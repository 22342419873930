.host__container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 64px;
}

.host__image {
    height: 100%;
    border-radius: 50%;
}

.host__name {
    margin-right: 10px;
    text-align: end;
    color: #FF6060;

    font-size: 20px;
    font-weight: 300;
}

/* Breakpoint Mobile */

@media all and (max-width: 767px) {

    .host__container {
        height: 10vw;
    }

    .host__name {
        font-size: 3vw;
    }

}