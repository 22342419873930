.page-not-found {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-not-found__number {
    margin: 0;
    padding: 0;
    font-size: 18vw;
    font-weight: 700;
    color: #FF6060;
}

.page-not-found__oops {
    font-size: 2.5vw;
    font-weight: 300;
    color: #FF6060;
}

.page-not-found__back {
    margin: 10vh 0;
    margin-bottom: 26vh;
    font-size: 1.4vw;
    color: #FF6060;
}


/* Breakpoint Grand Ecrans */

@media all and (min-width: 1921px) {

    .page-not-found__number {
        font-size: 350px;
    }

    .page-not-found__oops {
        font-size: 48px;
    }

    .page-not-found__back {
        font-size: 32px;
    }

}

/* Breakpoint Mobile */

@media all and (max-width: 767px) {

    .page-not-found__number {
        margin-top: 30vw;
        font-size: 28vw;
    }

    .page-not-found__oops {
        font-size: 3.5vw;
        text-align: center;
    }

    .page-not-found__back {
        margin-top: 30vw;
        font-size: 3.7vw;
        font-weight: 300;
    }

}