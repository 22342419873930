.accordion__container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    height: 100%;
}

.accordion__header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #FF6060;
    border-radius: 5px;
    z-index: 1;
}

.accordion__header__title {
    color: white;
    font-size: 1.8vw;
    font-weight: 400;
    margin: 10px 0;
}

.accordion__header__arrow {
    width: 2.5vw;
    max-width: 35px;
}

.accordion__content {
    background-color: #F6F6F6;
    flex-grow: 1;
    padding-top: 40px;
    margin-top: -10px;
    border-radius: 5px;
}

.accordion__content li {
    list-style: none;
    margin-left: -20px;
    padding-bottom: 5px;
}

.accordion__content ul {
    margin-top: 0;
    margin-bottom: 5px;
}

.accordion__content__text {
    color: #FF6060;
    font-weight: 300;
    font-size: 18px;
    margin: 0 20px;
    padding-bottom: 15px;
    text-align: justify;
}


/* Breakpoint Grand Ecrans */

@media all and (min-width: 1921px) {

    .accordion__header__title {
        font-size: 30px;
    }

}

/* Breakpoint Tablettes */

@media all and (max-width: 992px) {

    .accordion__content__text {
        font-size: 2vw;
    }

}

/* Breakpoint Mobile */

@media all and (max-width: 767px) {

    .accordion__header__title {
        font-size: 3.5vw;
        font-weight: 300;
    }

    .accordion__header__arrow {
        width: 20px;
    }

    .accordion__content__text {
        font-size: 3.5vw;
        padding-bottom: 10px;
    }

}