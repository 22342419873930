.housing-card__container {
    position: relative;
    background-size: cover;
    height: 20vw;
    width: 28%;
    margin: 1.5vw;
    border-radius: 10px;
}

.housing-card__container::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, .5) 100%);
    border-radius: 10px;
}

.housing-card__title {
    position: absolute;
    bottom: 8px;
    left: 16px;
    width: 85%;

    font-size: 18px;
    font-weight: 300;
    color: white;

    z-index: 1;
}

.housing-card__link {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    z-index: 1;
    cursor: pointer;
}


/* Breakpoint Grand Ecrans */

@media all and (min-width: 1921px) {

    .housing-card__container {
        height: 10vw;
        width: 20%;
        margin: 1.5vw;
        border-radius: 10px;
    }

}

/* Breakpoint Tablettes */

@media all and (max-width: 992px) {

    .housing-card__container {
            height: 20vw;
            width: 40%;
            margin: 20px 3vw;
            border-radius: 10px;
        }

}

/* Breakpoint Mobile */

@media all and (max-width: 767px) {

    .housing-card__container {
        height: 70vw;
        width: 100%;
        margin: 10px 0;
        border-radius: 10px;
    }

}