@import url('https: //fonts.googleapis.com/css?family=Montserrat');

* {
    font-family: Montserrat;
}

body {
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
}

main {
    flex-grow: 1;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100vh; 
}