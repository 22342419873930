footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: black;

    position: relative;
    bottom: 0;
}

.footer__logo {
    margin-top: 60px;
    margin-bottom: 45px;
}

.footer__copyright {
    color: white;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 40px;
}

/* Breakpoint Mobile */

@media all and (max-width: 767px) {

    .footer__logo {
        width: 28vw;
        margin-top: 10vw;
        margin-bottom: 5vw;
    }

    .footer__copyright {
        font-size: 2.5vw;
    }

}