.about__carrousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 100px;
}

.about__carrousel__accordion {
    margin: 15px 0;
    width: 85%;
}

/* Breakpoint Grand Ecrans */

@media all and (min-width: 1921px) {

    .about__carrousel {
        margin: 15px 25vw;
    }

}

/* Breakpoint Mobile */

@media all and (max-width: 767px) {

    .about__carrousel {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px 20px;
    }

    .about__carrousel__accordion {
        width: 100%;
    }

}
