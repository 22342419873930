.home__carousel {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #F6F6F6;
    margin: 40px 100px;
    padding: 25px;
    border-radius: 25px;
}


/* Breakpoint Grand Ecrans */

@media all and (min-width: 1921px) {

    .home__carousel {
        margin: 40px 15vw;
    }

}

/* Breakpoint Mobile */

@media all and (max-width: 767px) {

    .home__carousel {
            background-color: white;
            margin: 10px 20px;
            padding: 0px;
    }

}