.banner__container {
    display: flex;
    align-items: center;
    position: relative;
    background-size: cover;
    margin: 0 100px;
    border-radius: 25px;
    height: 12vw;
    background-color: black;
}

.banner__container::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 25px;
}

.banner__title {
    width: 100%;
    color: white;
    font-size: 3.7vw;
    font-weight: 300;
    text-align: center;
    z-index: 1;
}

.banner__title__mobile {
    display: none;
    visibility: hidden;
    margin-left: 20px;
    color: white;
    font-size: 1.6em;
    font-weight: 300;
    text-align: left;
    z-index: 1;
}

/* Breakpoint Grand Ecrans */

@media all and (min-width: 1921px) {

    .banner__container {
        max-height: 230px;
        margin: 0 15vw;
    }

    .banner__title {
        font-size: 62px;
        font-weight: 400;
    }

}

/* Breakpoint Mobile */

@media all and (max-width: 767px) {

    .banner__container {
        align-items: center;
        justify-content: flex-start;
        margin: 0 20px;
        border-radius: 10px;
        height: 110px;
    }

    .banner__container::before {
        border-radius: 10px;
    }

    .banner__title {
        display: none;
        visibility: hidden;
    }

    .banner__title__mobile {
        display: inline;
        visibility: visible;
    }

}